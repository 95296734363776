import React, { createContext, useContext, useState } from 'react';
import { signOut } from '../utils/auth';

const LogoutContext = createContext();

export const useLogout = () => useContext(LogoutContext);

export const LogoutProvider = ({ children }) => {
    const [showLogoutModal, setShowLogoutModal] = useState(false);

    const handleLogoutClick = () => {
        setShowLogoutModal(true);
    };

    const handleCancelLogout = () => {
        setShowLogoutModal(false);
    };

    const handleConfirmLogout = () => {
        setShowLogoutModal(false);
        signOut();
    };

    return (
        <LogoutContext.Provider value={{ handleLogoutClick, showLogoutModal, handleCancelLogout, handleConfirmLogout }}>
            {children}
        </LogoutContext.Provider>
    );
};
