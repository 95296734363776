import React from 'react';
import './stylesheets/ActionButton.css';

const ActionButton = ({ iconPath, text, onClick, color = "rgb(2, 125, 150)", fontSize = '20px', width = "100%", padding = "20px 20px", disabled = false, hidden = false }) => {
    const buttonStyle = {
        fontSize: fontSize,
        backgroundColor: color,
        width: width,
        padding: padding
    };

    return (
        <button className="action-button" style={buttonStyle} onClick={onClick} disabled={disabled} hidden={hidden}>
            {iconPath && <img src={iconPath} alt={`${text} Icon`} className="button-icon" />}
            {text}
        </button>
    );
};

export default ActionButton;
