// User will be directed here after they leave the meeting on the web.
import React from 'react';

const LeavePage = () => {

    return (
        <div className="leave-container">
            <h1>Thank you for conducting the meeting using Carex.ai!</h1>
        </div>
    );
};

export default LeavePage;
