import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const OAuthCallbackPage = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const accessToken = query.get('accessToken');
        const refreshToken = query.get('refreshToken');
        const expiresIn = query.get('expires_in');
        const scope = query.get('scope');

        console.table({ query, accessToken, refreshToken, expiresIn, scope })
        if (accessToken && refreshToken) {
            localStorage.setItem('zoomAccessToken', accessToken);
            localStorage.setItem('zoomRefreshToken', refreshToken);
            localStorage.setItem('zoomExpiresIn', expiresIn);
            localStorage.setItem('zoomScope', scope);
            navigate('/dashboard'); // Redirect to the dashboard page
        } else {
            navigate('/login'); // Redirect back to login if tokens are not present
        }
    }, [location, navigate]);

    // Optionally, display a loading message or spinner
    return <div>Loading...</div>;
};

export default OAuthCallbackPage;
