import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import MeetingPage from './pages/MeetingPage';
import PrivateRoutes from "./components/ProtectedRoutes"
import OauthCallbackPage from './pages/OauthCallbackPage';
import LeavePage from './pages/LeavePage';
import Header from './components/Header';
import DashboardPage from './pages/DashboardPage';
import './App.css'
import { LogoutProvider } from './components/LogoutContext';
function App() {
  return (
    <div className="App">
      <LogoutProvider>
        <Router>
          <Header />
          <main>
            <Routes>
              <Route path="/" element={<Navigate replace to="/dashboard" />} />
              <Route path="/oauth/callback" element={<OauthCallbackPage />} />
              <Route element={<PrivateRoutes />}>
                <Route element={<MeetingPage />} path="/meeting" />
                <Route element={<DashboardPage />} path="/dashboard" />
              </Route>
              <Route element={<LoginPage />} path='/login' />
              <Route element={<LeavePage />} path='/leave' />
            </Routes>
          </main>
        </Router>
      </LogoutProvider>
    </div>
  );
}


export default App;