import * as CryptoJS from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';

export const extractMeetingPassword = (meetingUrl) => {
    const url = new URL(meetingUrl);
    const queryParams = new URLSearchParams(url.search);
    return queryParams.get('pwd');
};

function generateAuthHeaders(secretKey, time, path, salt, apiKey) {
    const message = `${time}:${path}:${salt}`;
    const hash = CryptoJS.HmacSHA256(message, secretKey);
    const hashBase64 = CryptoJS.enc.Base64.stringify(hash);

    const headers = {
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
        'x-date': time,
        'x-salt': salt,
        Authorization: `carex ${hashBase64}`,
    };
    return headers;
}

export const getScreenItems = async (data, measurementId) => {
    const salt = uuidv4();
    const time = Math.floor(Date.now() / 1000);
    const path = '/measure/screen';
    let items;

    try {
        let screenURL = process.env.REACT_APP_URL_SCREEN;
        items = await fetch(screenURL, {
            method: 'POST',
            headers: generateAuthHeaders(process.env.REACT_APP_SECRET_KEY, time, path, salt, process.env.REACT_APP_API_KEY),
            body: JSON.stringify({
                detectionResult: data,
                detectionType: 'Face',
                measurementId: measurementId,
            }),
        });
    } catch (error) {
        console.error('Could not retrieve items from the endpoint.', error);
        return new Error('Fetch error');
    }

    if (items) return await items.json();
    return null;
}

/**
 * 
 * @param {Number} meetingNumber 
 * @param {String} meetingPassword 
 */
export const getDynamicLink = async (meetingNumber, meetingPassword) => {
    const firebaseUrl = process.env.REACT_APP_FIREBASE_URL;
    const res = await fetch(firebaseUrl, {
        method: 'POST',
        body: JSON.stringify({
            dynamicLinkInfo: {
                domainUriPrefix: process.env.REACT_APP_FIREBASE_DOMAIN_PREFIX,
                link:
                    process.env.REACT_APP_STAGE === 'prod'
                        ? `https://vitouch-zoom.carex.ai/?meeting_number=${meetingNumber}&password=${meetingPassword}`
                        : `https://${process.env.REACT_APP_STAGE}-vitouch-zoom.carex.ai/?meeting_number=${meetingNumber}&password=${meetingPassword}`,
                androidInfo: {
                    androidPackageName: process.env.REACT_APP_ANDROID_PACKAGE_NAME,
                },
                iosInfo: {
                    iosBundleId: process.env.REACT_APP_IOS_BUNDLE_ID,
                },
            },
        }),
    });
    const jsonResponse = await res.json();
    return jsonResponse.shortLink;
}

/**
 * 
 * @param {String} patientId
 * @param {String} meetingNumber
 * @param {String} meetingPassword
 */
export const invitePatient = async (patientId, meetingNumber, meetingPassword) => {
    // convert meetingNumber to string
    const invitationURL = process.env.REACT_APP_INVITATION_URL;
    console.log(`Sending request to invite patient ${patientId} to meeting ${meetingNumber} with password ${meetingPassword}...`)
    const res = await fetch(invitationURL, {
        method: 'POST',
        headers: {
            'x-os': 'Web',
        },
        body: JSON.stringify({
            userSpecialCode: patientId,
            meetingNumber: meetingNumber.toString(),
            password: meetingPassword,
            userToken: "Bearer " + localStorage.getItem('zoomAccessToken'),
        }),
    });
    return await res.json();
}


/**
 * 
 * @param {String} previousState
 * @param {String} receivedState
 */
export const isMeasurementInterrupted = (previousState, receivedState) => {
    console.log("previousState: ", previousState)
    console.log("receivedState: ", receivedState)
    if (receivedState === "MEASUREMENT_DETECTING" && ["MEASUREMENT_DISTANCE", "MEASUREMENT_STARTED", "MEASUREMENT_UPDATED"].includes(previousState)) {
        console.log("MEASUREMENT_INTERRUPTED_DETECTING")
        return "MEASUREMENT_INTERRUPTED_DETECTION"

        // THE MEASUREMENT WAS INTERRUPTED BECAUSE OF FACE DETECTION
    } else if (receivedState === "MEASUREMENT_DISTANCE" && ["MEASUREMENT_DETECTING", "MEASUREMENT_STARTED", "MEASUREMENT_UPDATED"].includes(previousState)) {
        console.log("MEASUREMENT_INTERRUPTED_DETECTING")
        return "MEASUREMENT_INTERRUPTED_DISTANCE"

        // THE MEASUREMENT WAS INTERRUPTED BECAUSE OF OPTIMAL DISTANCE
    }

    return null;
}

export const endMeeting = () => {
    console.log((localStorage.getItem('meetingDetails'), JSON.parse(localStorage.getItem('meetingDetails'))), JSON.parse(localStorage.getItem('meetingDetails'))["storedMeetingNumber"])
    const data = JSON.stringify({
        meetingId: JSON.parse(localStorage.getItem('meetingDetails'))["storedMeetingNumber"].toString(),
        token: 'Bearer ' + localStorage.getItem('zoomAccessToken')
    });
    navigator.sendBeacon(`${process.env.REACT_APP_CAREX_ZOOM_SERVICE_ENDPOINT}/meetings/end`, data);
}