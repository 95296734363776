import { endMeeting } from "./helpers";

const isAuthenticated = () => {
    const accessToken = localStorage.getItem('zoomAccessToken');
    const refreshToken = localStorage.getItem('zoomRefreshToken');

    return !!accessToken && !!refreshToken;
};

const signOut = async () => {
    endMeeting()
    await revokeToken()
    localStorage.removeItem('zoomAccessToken');
    localStorage.removeItem('zoomRefreshToken');
    localStorage.removeItem('zoomExpiresIn');
    window.location.href = '/login';
}

const revokeToken = async () => {
    try {
        console.log("Revoking token...")
        await fetch(`${process.env.REACT_APP_CAREX_ZOOM_SERVICE_ENDPOINT}/revoke`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: localStorage.getItem('zoomAccessToken') })
        });
        console.log("Token revoked.")
    } catch (error) {
        console.error('Error revoking token:', error);
    }
};

// TODO: depedency inject the navigate
const refreshToken = async (onFail) => {
    try {
        console.log("Refreshing token...")
        const response = await fetch(`${process.env.REACT_APP_CAREX_ZOOM_SERVICE_ENDPOINT}/oauth/refresh`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ refresh_token: localStorage.getItem('zoomRefreshToken') })
        });
        const data = await response.json();
        console.log("Tokens Received.")
        console.table(data)
        if (data.access_token && data.refresh_token && data.expires_in) {
            localStorage.setItem('zoomAccessToken', data.access_token);
            localStorage.setItem('zoomRefreshToken', data.refresh_token);
            localStorage.setItem('zoomExpiresIn', data.expires_in)
            return data.access_token;
        } else {
            throw new Error('Failed to refresh token');
        }
    } catch (error) {
        console.error('Error refreshing token:', error);
        localStorage.removeItem('zoomAccessToken');
        localStorage.removeItem('zoomRefreshToken');
        localStorage.removeItem('zoomExpiresIn');

        if (onFail && typeof onFail === 'function') {
            onFail();
        }
        return null;
    }
};

export { isAuthenticated, refreshToken, signOut }