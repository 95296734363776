import ZoomMtgEmbedded from '@zoom/meetingsdk/embedded';

const client = ZoomMtgEmbedded.createClient();
const zoomUtils = {
    startMeeting: function (signature, sdkKey, meetingNumber, passWord, userName, userEmail, registrantToken, zakToken, invitationURL, onMeetingEnd, setPatientId, onMeetingStarted) {
        let meetingSDKElement = document.getElementById('meetingSDKElement')
        const screenWidth = window.innerWidth;
        const zoomWidth = screenWidth * 0.55;

        const invitationButton = {
            onClick: function () {
                navigator.clipboard.writeText(invitationURL).then(() => {
                    alert('Patient invitation URL copied to clipboard.');
                }).catch(err => {
                    alert('Could not copy text: ', err);
                });
            },
            text: 'Copy Meeting URL',
            className: 'test-class'
        }

        client.init({
            zoomAppRoot: meetingSDKElement,
            language: 'en-US',
            patchJsMedia: true,
            customize: {
                video: {
                    isResizable: true,
                    popper: {
                        disableDraggable: false
                    },
                    viewSizes: {
                        default: {
                            width: zoomWidth,
                            height: 411
                        }
                    },
                    defaultViewType: 'gallery',
                },
                toolbar: {
                    buttons: [invitationButton]
                }
            }
        }).then(() => {
            client.join({
                signature: signature,
                sdkKey: sdkKey,
                meetingNumber: meetingNumber,
                password: passWord,
                userName: userName,
                userEmail: userEmail,
                tk: registrantToken,
                zak: zakToken
            }).then(() => {
                client.reclaimHost()
                client.on('connection-change', (payload) => {
                    console.log("Connection changed", payload);
                    if (payload.state && payload.state === 'Closed') {
                        onMeetingEnd('Meeting has ended.');
                        // Remove the meeting details from the local storage if we are ending/leaving the meeting
                        // localStorage.removeItem('meetingDetails')
                    }

                })
                client.on('user-added', (payload) => {
                    console.log("User-added", payload);
                    if (payload && payload.length > 0 && payload[0].displayName) {
                        // Set the patientId if the user is not on hold, meaning they joined and are no longer in the waiting room
                        if (!payload[0].bHold)
                            setPatientId(payload[0].displayName)
                    } else {
                        alert("Unable to retrive display name of the user. You will not be able to recieve updates.")
                    }
                })
                client.on('user-removed', (payload) => {
                    console.log("User-removed", payload);
                    if (payload && payload.length > 0 && payload[0].displayName) {
                        // If the meeting contains contains another patient then set the patientId to the new patient
                        const attendees = client.getAttendeeslist()
                        console.log("All attendees", attendees)
                        // Check if the patientId is not the doctor. Since sometimes we have duplicate doctor entities in the zoom meeting.
                        if (payload[0].displayName !== userName) {
                            if (attendees.length > 1) {
                                // Iterate over the attendees and set the patientId as the first patient whose name is not the same as the current user and is a 6 digit number
                                for (let i = 0; i < attendees.length; i++) {
                                    if (attendees[i].userName !== userName && attendees[i].userName.length === 6) {
                                        setPatientId(attendees[i].userName)
                                        break;
                                    }
                                }
                            } else {
                                setPatientId('')
                            }
                        }
                    }
                })

                onMeetingStarted(client.getAttendeeslist().filter(attendant => attendant.userName !== userName))

                storeMeetingDetailsInLocalStorage(meetingNumber, passWord);

                console.log('joined successfully')
            }).catch((error) => {
                console.error(error)
                onMeetingEnd(error)
            })
        }).catch((error) => {
            console.log(error)
            onMeetingEnd(`Error initilizaing the meeting. ${JSON.stringify(error)}`);
        })
    },
    getPatientDisplayName: function () {
        const participants = client.getAttendeeslist()
        // return participants[0].userName
        console.log(participants)
    }

};

const storeMeetingDetailsInLocalStorage = (meetingNumber, meetingPassword, expiration = Date.now() + 60 * 60 * 1000) => {
    const meetingDetails = {
        storedMeetingNumber: meetingNumber, storedMeetingPassword: meetingPassword, expiration
    };
    console.log("meeting details in meeting page", meetingDetails)
    localStorage.setItem('meetingDetails', JSON.stringify(meetingDetails));
    console.log("Retri", localStorage.getItem('meetingDetails'))
}


export default zoomUtils;
