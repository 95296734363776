import React from 'react';
import './stylesheets/Modal.css';

const Modal = ({ isVisible, title, message, onCancel, onConfirm }) => {
  if (!isVisible) return null;

  return (
    <div className="modal-overlay">
      <div className="modal">
        <h2>{title}</h2>
        <p>{message}</p>
        <div className="modal-buttons">
          <button className="modal-button" onClick={onCancel}>Cancel</button>
          <button className="modal-button" onClick={onConfirm}>Confirm</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
