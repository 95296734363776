import React from 'react';
import Vital from './Vital';
import "./stylesheets/Vital.css"
import "./stylesheets/VitalsColumn.css"

function VitalsColumn({ vitalsData = [] }) {
    const keyIconMap = {
        "heartRate": "images/heartRate.png",
        "heartRateVariability": "images/heartRateVariability.png",
        "respiratoryRate": "images/respiratoryRate.png",
        "stressIndex": "images/stressIndex.png",
    }

    return (
        <div>
            {vitalsData.map((item, index) => (
                <Vital
                    key={item.key}
                    title={item.title}
                    value={item.value}
                    unit={item.unit}
                    iconURL={keyIconMap[item.key] || item.iconURL}
                />
            ))}
        </div>
    );
}

export default VitalsColumn;
