import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './stylesheets/DashboardPage.css';
import { refreshToken } from '../utils/auth';
import ActionButton from '../components/ActionButton';
import { useLogout } from '../components/LogoutContext';
import Modal from '../components/Modal';

const DashboardPage = () => {
    const Roles = {
        START: 1,
        JOIN: 0
    }
    const maxRetryCount = 1;
    const navigate = useNavigate();
    const [zakToken, setZakToken] = useState(null);
    const [isMeetingDataReady, setIsMeetingDataReady] = useState(false);
    const [userDetails, setUserDetails] = useState({
        displayName: '',
        email: '',
    });
    const [meetingDetails, setMeetingDetails] = useState({
        meetingNumber: '',
        meetingPassword: '',
    });
    const [isStarting, setIsStarting] = useState(false);
    const [isReconnecting, setIsReconnecting] = useState(false);
    const [isWaitingRoomEnabled, setIsWaitingRoomEnalbed] = useState(false)
    const [isMeetingPasswordEnabled, setIsMeetingPasswordEnabled] = useState(false)
    const [showReconnectButton, setShowReconnectButton] = useState(false)

    const { showLogoutModal, handleCancelLogout, handleConfirmLogout } = useLogout();

    let pollingInterval = useRef();

    async function fetchMeetingById(meetingId) {
        await fetch(`${process.env.REACT_APP_CAREX_ZOOM_SERVICE_ENDPOINT}/meetings/id`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('zoomAccessToken')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ meetingId: meetingId.toString() })
        }).then(response => response.json()).then(data => {
            if (data && data["code"]) {
                setShowReconnectButton(false);
            }
        })
    }

    useEffect(() => {
        async function fetchData() {
            try {
                // Refresh the token before fetching the data
                await refreshToken(() => navigate('/login'));

                // Check for reconnection
                console.log("Checking reconnection")
                const meetingDetails = localStorage.getItem('meetingDetails');
                console.log("Meeting details", meetingDetails)
                if (meetingDetails) {
                    const { storedMeetingNumber, storedMeetingPassword, expiration } = JSON.parse(meetingDetails);
                    // TODO: change this to a GET request in the backend
                    await fetch(`${process.env.REACT_APP_CAREX_ZOOM_SERVICE_ENDPOINT}/meetings/id`, {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('zoomAccessToken')}`,
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ meetingId: storedMeetingNumber.toString() })
                    }).then(response => response.json()).then(data => {
                        // This code means that the meeting we tried to get doesn't exist
                        if (data && !!!data["code"] && Date.now() <= expiration && storedMeetingNumber && storedMeetingPassword) {
                            setShowReconnectButton(true);
                            // Fetch the meeting id every 2 seconds
                            setTimeout(() => {
                                pollingInterval.current = setInterval(() => fetchMeetingById(storedMeetingNumber), 3000);
                            }, 1000);


                        } else {
                            // localStorage.removeItem('meetingDetails');
                            setShowReconnectButton(false);
                        }
                    })
                }
                // asynchronsly fetch the rest of the data and wait for all to finish
                await Promise.all([fetchZakToken(), fetchZoomUserData(), enableWaitingRoom(), enableMeetingPassword(), fetchMeetingDetails()]);
            } catch (error) {
                console.error(error)
            }
        }
        fetchData()
    }, []);

    useEffect(() => {
        if (!showReconnectButton) {
            return () => {
                clearInterval(pollingInterval.current);
            }
        }
    }, [showReconnectButton])

    useEffect(() => {
        //? Are we guarnateed to get a display name here? userDetails.displayName
        if (userDetails.displayName && zakToken && isWaitingRoomEnabled && meetingDetails.meetingNumber && isMeetingPasswordEnabled) {
            setIsMeetingDataReady(true);
        }
    }, [userDetails, zakToken, isWaitingRoomEnabled, meetingDetails, isMeetingPasswordEnabled]);

    const fetchZoomUserData = async (retryCount = 0) => {
        const accessToken = localStorage.getItem('zoomAccessToken');
        console.log("Retrieving user info...")
        const response = await fetch(`${process.env.REACT_APP_CAREX_ZOOM_SERVICE_ENDPOINT}/users/me`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });

        if (!response.ok) {
            let textResponse = await response.text()
            console.log("Response:", textResponse)
            if (response.status === 401 && textResponse === "Access token is expired." && retryCount < maxRetryCount) {
                // If the response is 401 Unauthorized, try to refresh the token
                const newAccessToken = await refreshToken(() => navigate('/login'));
                if (newAccessToken) {
                    return await fetchZoomUserData(retryCount + 1); // Retry with the new token
                } else {
                    // If the refresh token process fails, navigate to login
                    navigate('/login');
                    return;
                }
            } else {
                // For other errors, throw an error to be caught by the catch block
                throw new Error(`API Request Failed: ${response.status}`);
            }
        }

        const data = await response.json();
        if (data) {
            setUserDetails({
                email: data.email,
                displayName: data.display_name
            });
            console.log("User info set.")
        }
    };

    const fetchMeetingDetails = async (retryCount = 0) => {
        const accessToken = localStorage.getItem('zoomAccessToken');
        console.log("Creating instant meeting...")
        const response = await fetch(`${process.env.REACT_APP_CAREX_ZOOM_SERVICE_ENDPOINT}/meetings/instant`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: {}
        });

        if (!response.ok) {
            let textResponse = await response.text()
            console.log("Response:", textResponse)
            if (response.status === 401 && textResponse === "Access token is expired." && retryCount < maxRetryCount) {
                // If the response is 401 Unauthorized, try to refresh the token
                const newAccessToken = await refreshToken(() => navigate('/login'));
                if (newAccessToken) {
                    return await fetchZoomUserData(retryCount + 1); // Retry with the new token
                } else {
                    // If the refresh token process fails, navigate to login
                    navigate('/login');
                    return;
                }
            } else {
                // For other errors, throw an error to be caught by the catch block
                throw new Error(`API Request Failed: ${response.status}`);
            }
        }

        const meetingDetails = await response.json();
        if (meetingDetails) {
            if (!meetingDetails.id)
                throw new Error("Created meeting didn't return an id")

            if (!meetingDetails.password)
                throw new Error("Created meeting didn't return a password")

            console.log("Instant meeting created.")
            setMeetingDetails({
                meetingNumber: meetingDetails.id,
                meetingPassword: meetingDetails.password,
            });
        }

    };

    const enableWaitingRoom = async (retryCount = 0) => {
        const accessToken = localStorage.getItem('zoomAccessToken');
        console.log("Enabling waiting room...")
        const response = await fetch(`${process.env.REACT_APP_CAREX_ZOOM_SERVICE_ENDPOINT}/user/enable-waiting-room`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });

        if (!response.ok) {
            let textResponse = await response.text()
            console.log("Response:", textResponse)
            if (response.status === 401 && textResponse === "Access token is expired." && retryCount < maxRetryCount) {
                const newAccessToken = await refreshToken(() => navigate('/login'));
                if (newAccessToken) {
                    return await enableWaitingRoom(retryCount + 1); // Retry with the new token
                } else {
                    navigate('/login');
                    return;
                }
            } else {
                throw new Error(`API Request Failed: ${response.status}`);
            }
        }
        setIsWaitingRoomEnalbed(true)
        console.log("User settings updated.")
    };

    const enableMeetingPassword = async (retryCount = 0) => {
        const accessToken = localStorage.getItem('zoomAccessToken');
        console.log("Enabling meeting password...")
        const response = await fetch(`${process.env.REACT_APP_CAREX_ZOOM_SERVICE_ENDPOINT}/user/enable-meeting-password`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });

        if (!response.ok) {
            let textResponse = await response.text()
            console.log("Response:", textResponse)
            if (response.status === 401 && textResponse === "Access token is expired." && retryCount < maxRetryCount) {
                const newAccessToken = await refreshToken(() => navigate('/login'));
                if (newAccessToken) {
                    return await enableMeetingPassword(retryCount + 1); // Retry with the new token
                } else {
                    navigate('/login');
                    return;
                }
            } else {
                throw new Error(`API Request Failed: ${response.status}`);
            }
        }
        setIsMeetingPasswordEnabled(true)
        console.log("Meeting password enabled.")
    };

    const fetchZakToken = async (retryCount = 0) => {
        const accessToken = localStorage.getItem('zoomAccessToken');
        console.log("Retrieving Zak token...")
        const response = await fetch(`${process.env.REACT_APP_CAREX_ZOOM_SERVICE_ENDPOINT}/zak`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });

        if (!response.ok) {
            let textResponse = await response.text()
            console.log("Response:", textResponse)
            if (response.status === 401 && textResponse === "Access token is expired." && retryCount < maxRetryCount) {
                // If the response is 401 Unauthorized, try to refresh the token
                const newAccessToken = await refreshToken(() => navigate('/login'));
                if (newAccessToken) {
                    return await fetchZakToken(retryCount + 1); // Retry with the new token
                } else {
                    // If the refresh token process fails, navigate to login
                    navigate('/login');
                    return;
                }
            } else {
                // For other errors, throw an error to be caught by the catch block
                throw new Error(`API Request Failed: ${response.status}`);
            }
        }

        const data = await response.json();
        if (data) {
            setZakToken(data.token);
            console.log("Zak token set")
        }
    };

    const fetchZoomSignature = async (meetingNumber, role) => {
        const response = await fetch(`${process.env.REACT_APP_CAREX_ZOOM_SERVICE_ENDPOINT}/join/jwt`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ meetingNumber: meetingNumber.toString(), role: role })
        });

        if (!response.ok) {
            throw new Error('Failed to fetch Zoom signature');
        }

        const data = await response.json();
        return data.signature
    };

    const startZoomMeeting = async () => {
        setIsStarting(true);
        const signature = await fetchZoomSignature(meetingDetails.meetingNumber, Roles.START)
        setIsStarting(false);
        navigate('/meeting', { state: { signature, meetingDetails, userDetails, zakToken } });
    }

    const reconnectToZoomMeeting = async () => {
        console.log("Clearning interval")
        clearInterval(pollingInterval.current);
        const storedMeetingDetails = localStorage.getItem('meetingDetails');
        const { storedMeetingNumber, storedMeetingPassword } = JSON.parse(storedMeetingDetails);
        setIsReconnecting(true);
        const signature = await fetchZoomSignature(storedMeetingNumber, Roles.START)
        setIsReconnecting(false);

        const meetingDetails = { meetingNumber: storedMeetingNumber, meetingPassword: storedMeetingPassword }
        navigate('/meeting', {
            state: { signature, meetingDetails, userDetails, zakToken }
        });
    }

    return (
        <div className="meeting-container">
            <div className="meeting-box">
                <h1 className='displayName'>{userDetails.displayName ? `Welcome ${userDetails.displayName}` : "Loading your details, please wait..."}</h1>

                {isMeetingDataReady && !showReconnectButton && (
                    <ActionButton
                        iconPath="images/zoom.png"
                        text={isStarting ? "Starting Meeting..." : "Start Zoom Meeting"}
                        padding='10px 10px'
                        onClick={() => startZoomMeeting()}
                        disabled={!isMeetingDataReady || isStarting || isReconnecting}
                    />
                )}
                {isMeetingDataReady && showReconnectButton && (
                    <ActionButton
                        // iconPath="images/zoom.png"
                        text={isReconnecting ? "Reconnecting to Meeting..." : "Reconnect to Your Last Meeting"}
                        onClick={() => reconnectToZoomMeeting()}
                        width='80%'
                        color='green'
                        fontSize='16px'
                        disabled={!isMeetingDataReady || isStarting || isReconnecting}
                    />)}
                {isStarting && <div className="loading-spinner"></div>}

            </div>
            <Modal
                isVisible={showLogoutModal}
                title="Logout Confirmation"
                message= {showReconnectButton ? "Logging out will end any ongoing meetings. Are you sure?": ""}
                onCancel={handleCancelLogout}
                onConfirm={handleConfirmLogout}
            />

        </div>
    );
};

export default DashboardPage;