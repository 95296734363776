import React, { useEffect, useState } from 'react';
import './stylesheets/Vital.css';
import MoonLoader from 'react-spinners/MoonLoader';

function Vital({ title, value, unit, iconURL }) {
    const [imageURL, setImageURL] = useState('');

    const override = {
        margin: '8px 0 0 4px',
        borderColor: 'red',
    };

    useEffect(() => {
        // Case where the iconURL is a remote URL. Meaning it's a network image.
        if (iconURL && iconURL.startsWith('http')) {
            (async () => {
                const res = await fetch(iconURL);
                const imageBlob = await res.blob();
                const imageObjectURL = URL.createObjectURL(imageBlob);
                setImageURL(imageObjectURL);
            })();
        } else {
            // Case where the iconURL is a relative path. Meaning it's a local image.
            setImageURL(iconURL);
        }
    }, [iconURL]);

    return (
        <div className="vital">
            <img alt="Icon" src={imageURL} className="measurementIcon" />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {(value && <span className="vitalValue">{value + ' ' + unit}</span>) || (
                    <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: '4px' }}>
                        {!!!value && (
                            <MoonLoader size={17} color='cyan' cssOverride={override} speedMultiplier={0.5} />
                        )}
                    </div>
                )}
                <span className="iconDesc">{title}</span>
            </div>
        </div>
    );
}

export default Vital;

