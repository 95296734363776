import React from 'react';
import ProgressBar from '@ramonak/react-progress-bar';
import './stylesheets/MeasurementProgress.css';

export default function MeasurementProgress({ progress }) {
    return (
        progress == 1 ? <div className="loading-spinner"></div> :
            <span className="timer">
                <ProgressBar completed={Math.round(progress * 100)} width={'300px'} bgColor={'#00c8da'} customLabel={' '} />
            </span>
    );

}
